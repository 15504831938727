import React from "react";

const ContactUs = () => {
  return (
    <div className="w-full h-auto lg:h-[100vh] bg-zinc-900 flex justify-center  items-center">
      <div className="w-[800px] h-[500px] rounded-lg bg-white ">
        <div className="w-full h-[70%]   flex justify-center items-center">
          <a
            href="mailto:Mosterautorepair@gmail.com"
            className="text-2xl  cursor-pointer font-bold "
          >
            <div className="w-[250px] cursor-pointer  h-[250px] bg_ContactUs  "></div>
          </a>
        </div>
        <div className=" w-full h-[20%]   flex justify-center items-center">
          <a
            href="mailto:Mosterautorepair@gmail.com"
            className="text-2xl  sendEmail cursor-pointer font-bold "
          >
            {" "}
            Contact us with a <span className="border_AboutUs">click </span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
