import React from "react";
import "./Home.css";

const Home = () => {
  return (
    <div className="homeDiv">
      <div className="homeDivBg">
        <div>
          <p className=" text-7xl font-bold text-white">
            MOSTER AUTO <br /> REPAIR
          </p>
        </div>
      </div>
    </div>
  );
};

export default Home;
