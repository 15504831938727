import React from "react";

const Location = () => {
  return (
    <div className="w-full h-[80vh] flex justify-center  items-center flex-col">
      <div className="w-auto h-">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3090.0414013549407!2d-76.62901402361639!3d39.241931826301034!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c802dbb67858cf%3A0x83e59dd0f656821f!2s600%20W%20Patapsco%20Ave%2C%20Baltimore%2C%20MD%2021225%2C%20EE.%20UU.!5e0!3m2!1ses-419!2sdo!4v1704914666842!5m2!1ses-419!2sdo"
          width="300"
          height="300"
          style={{ border: 0 }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>

      <div className="mt-4">
        <p className="text-2xl">Our address</p>
      </div>
    </div>
  );
};

export default Location;
